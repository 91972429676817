import React, { useContext, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
import { Button, Section } from "../components/Core";
import { navigate } from "gatsby"
import { Container, Col, Row } from "react-bootstrap";


const ExpiredPage = () => {
    const gContext = useContext(GlobalContext);

    return (
        <Section className="green-bg text-center">
            <Container>
                <Row className="justify-content-center mt-80 mb-80">
                    <Col lg={8} md={10} sm={8}>
                        <h2>This offer has expired.</h2>
                        <p>
                            We encourage you to stay in the loop with our latest promotions and deals by regularly visiting our website.<br></br>
                            Thank you for your understanding, and we look forward to providing you with exciting offers in the future.
                        </p>
                        <Button
                            onClick={() => {
                                localStorage.removeItem('registrationData');
                                gContext.goResetRegistrationData();
                                navigate('/');
                            }}
                            className="mt-50 light-bg"
                        >Return to homepage</Button>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default ExpiredPage